import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import { AxiosResponse } from 'axios';
import {
  IAutoSellTokenBotTransactionsRequestParams,
  IAutoSellTokenBotTransactionsListResponse,
  TAutoSellTokenBotInfoData,
  TAutoSellTokenBotSettings,
  TAutoSellTokenBotSettingsRequest,
  IAutoSellTokenBotTransactionsCsvRequestParams
} from './schema';

/**
 * Get crypto bot info status.
 * @return Promise with crypto bot info.
 */
export async function getAutoSellTokenBotInfo(): Promise<TAutoSellTokenBotInfoData> {
  return http
    .get(buildUrl(`/api/v1/super-admin/auto-sell-token-bot/{operatorId}/bot`), {
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): TAutoSellTokenBotInfoData => data);
}

/**
 * Get crypto bot info status.
 * @return Promise with crypto bot info.
 */
export async function updateAutoSellTokenBotInfo(
  payload: TAutoSellTokenBotInfoData
): Promise<any> {
  return http.post(
    buildUrl(`/api/v1/super-admin/auto-sell-token-bot/{operatorId}/bot`),
    payload,
    {
      cacheTime: 0
    }
  );
}

/**
 * Get crypto bot total sold info.
 * @return Promise with crypto bot total sold info.
 */
export async function getAutoSellTokenBotTotalSold(): Promise<number> {
  return http
    .get(
      buildUrl(
        `/api/v1/super-admin/auto-sell-token-bot/{operatorId}/bot/total-sold`
      )
    )
    .then(({ data }: AxiosResponse): number => data);
}

export async function getAutoSellTokenBotTransactions(
  requestData: IAutoSellTokenBotTransactionsRequestParams
): Promise<IAutoSellTokenBotTransactionsListResponse> {
  return http
    .get(
      buildUrl(
        '/api/v1/super-admin/auto-sell-token-bot/{operatorId}/bot/transactions'
      ),
      { params: requestData }
    )
    .then(
      ({ data }: AxiosResponse): IAutoSellTokenBotTransactionsListResponse =>
        data
    );
}

export async function getAutoSellTokenBotTransactionsCsv(
  requestData: IAutoSellTokenBotTransactionsCsvRequestParams
): Promise<any> {
  return http.get(
    buildUrl(
      '/api/v1/super-admin/auto-sell-token-bot/{operatorId}/bot/transactions/csv'
    ),
    { params: requestData }
  );
}

/**
 * Get liquidity settings.
 * @return Promise with liquidity settings.
 */
export async function getAutoSellTokenBotSettings(): Promise<TAutoSellTokenBotSettings> {
  return http
    .get(
      buildUrl('/api/v1/{operatorId}/blockchain-wallet/autosellBot-settings'),
      {
        cacheTime: 0
      }
    )
    .then(({ data }: AxiosResponse): TAutoSellTokenBotSettings => data);
}

export async function saveAutoSellTokenBotSettings(
  settingsRequest: TAutoSellTokenBotSettingsRequest
): Promise<TAutoSellTokenBotSettings> {
  return http
    .post(
      buildUrl('/api/v1/{operatorId}/blockchain-wallet/autosellBot-settings'),
      settingsRequest
    )
    .then(({ data }: AxiosResponse): TAutoSellTokenBotSettings => data);
}
