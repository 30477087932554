




























import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
import * as vr from '@/helpers/validation';

type TTimeValue = {
  HH: string;
  mm: string;
};

export default {
  name: 'FieldTimePicker',
  components: { VueTimepicker },
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Select Time'
    },
    placeholder: {
      type: String,
      default: 'hh:mm'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    closeOnComplete: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      dropdownStatus: false
    };
  },
  computed: {
    modelValue: {
      get(): TTimeValue | null {
        if (this.value && this.validTime) {
          const timeString = this.value;

          const [HH, mm] = timeString.split(':');

          return {
            HH,
            mm
          } as TTimeValue;
        }

        return null;
      },
      set(value: TTimeValue): void {
        const { HH, mm } = value;

        if (HH || mm) {
          const hours = HH ? HH : 'hh';
          const minutes = mm ? mm : 'mm';

          this.$emit('input', `${hours}:${minutes}`);
        }
      }
    },
    rules(): any {
      return [
        this.required ? true : vr.required,
        this.dropdownStatus
          ? true
          : () => {
              return this.validTime || 'Invalid time';
            }
      ];
    },
    validTime(): boolean {
      return vr.validTimeFormat(this.value);
    }
  },
  methods: {
    focusHandler(): void {
      this.$refs.vueTimepicker?.onFocus();
    },
    clearHandler(): void {
      this.$refs.vueTimepicker?.clearTime();
    }
  }
};
