import { http } from './Connect';
import type { AxiosResponse } from 'axios';
import type {
  OperatosMerchantResponce,
  OperatorMerchantsRequestParams,
  CustodyTransactionResponse,
  CustodyTransactionRequestParams,
  MerchantTokenBalance,
  MerchantTokenBalanceRequestParams,
  MerchantTokenWithdraw,
  MerchantTransactionRequestParams,
  MerchantTransactionDataResponse,
  MerchantUserWalletRequestParams,
  MerchantUserWalletResponse,
  MerchantFinanceWalletBalance
} from '@/api/schema';

/**
 * Get all operators merchant.
 * @params request params data.
 */
export async function getOperatorsMerchant(
  params: OperatorMerchantsRequestParams
): Promise<OperatosMerchantResponce> {
  return http
    .get('/api/v1/token-gateway/merchant', {
      params: {
        ...params
      }
    })
    .then(({ data }: AxiosResponse): OperatosMerchantResponce => data);
}

/**
 * Get all operators transactions.
 * @params request params data.
 */
export async function getOperatorsTransactions(
  params: CustodyTransactionRequestParams
): Promise<CustodyTransactionResponse> {
  return http
    .get('/api/v1/token-gateway/transaction', {
      params: {
        ...params
      }
    })
    .then(({ data }: AxiosResponse): CustodyTransactionResponse => data);
}

/**
 * Get operators transactions csv report.
 * @return Promise with operator transactions csv report.
 */
export async function getOperatorTransactionsCSVReport(
  params: CustodyTransactionRequestParams
): Promise<any> {
  return http
    .get('/api/v1/token-gateway/transaction/csv', { params })
    .then(({ data }: AxiosResponse<any>) => data);
}

/**
 * Get merchant token balance.
 * @params request params data.
 */
export async function getOperatorsMerchantTokenBalance(
  params: MerchantTokenBalanceRequestParams
): Promise<MerchantTokenBalance> {
  return http
    .get('/api/v1/token-gateway/wallet-balance', {
      params: {
        ...params
      }
    })
    .then(({ data }: AxiosResponse): MerchantTokenBalance => data);
}

/**
 * Merchant withdraw.
 * @params request params data.
 */
export async function operatorsMerchantWithdraw(
  merchantId: number,
  networkId: number,
  data: MerchantTokenWithdraw
): Promise<any> {
  return http
    .post(
      `/api/v1/token-gateway/merchant/${merchantId}/withdraw?networkId=${networkId}`,
      data
    )
    .then(({ data }: AxiosResponse): any => data);
}

/**
 * Get merchant transactions by token.
 * @params request params data.
 */
export async function getOperatorsMerchantTransaction(
  params: MerchantTransactionRequestParams
): Promise<MerchantTransactionDataResponse> {
  return http
    .get(`/api/v1/token-gateway/merchant/transaction`, { params })
    .then(({ data }: AxiosResponse): MerchantTransactionDataResponse => data);
}

/**
 * Get merchant users.
 * @params request params data.
 */
export async function getMerchantUsers(
  merchantId: number,
  params: MerchantUserWalletRequestParams
): Promise<MerchantUserWalletResponse> {
  return http
    .get(`/api/v1/token-gateway/merchant/${merchantId}/wallet`, { params })
    .then(({ data }: AxiosResponse): MerchantUserWalletResponse => data);
}

/**
 * Get merchant user transactions.
 * @params request params data.
 */
export async function getMerchantUserTransactions(
  userId: number,
  params: CustodyTransactionRequestParams
): Promise<CustodyTransactionResponse> {
  return http
    .get(`/api/v1/token-gateway/user/${userId}/transaction`, { params })
    .then(({ data }: AxiosResponse): CustodyTransactionResponse => data);
}

export async function getFinanceWalletBalance(): Promise<MerchantFinanceWalletBalance> {
  return http
    .get('/api/v1/token-gateway/merchant/finance-wallet-balance')
    .then(({ data }: AxiosResponse): MerchantFinanceWalletBalance => data);
}
